import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as common from '@rushplay/common'
import * as forms from '@rushplay/forms'
import * as Processes from '@rushplay/processes'
import { useI18n } from '@rushplay/i18n'

import * as icons from './icons'
import * as ClientConfig from './client-configuration'
import * as Session from './session'
import { EmailPattern } from './constants'
import { InputField } from './input-field'
import { InputPasswordField } from './input-password-field'
import { Spinner } from './spinner'
import { SubmitButton } from './submit-button'

const dataSchema = {
  type: 'object',
  properties: {
    email: {
      type: 'string',
      pattern: EmailPattern,
    },
    password: {
      type: 'string',
    },
  },
  required: ['email', 'password'],
}

export function LoginFormBoom() {
  const i18n = useI18n()
  const dispatch = ReactRedux.useDispatch()

  const clientType = ClientConfig.useClientType()

  const loading = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: ['LOGIN_LOADING'],
    })
  )

  return (
    <common.Box borderRadius={1} width="100%">
      <forms.Provider
        name="login"
        schema={dataSchema}
        onSubmit={(errors, userData) => {
          if (R.isEmpty(errors)) {
            dispatch([
              Session.login(
                userData.email.trim(),
                userData.password.trim(),
                clientType
              ),
              Processes.start('LOGIN_LOADING'),
            ])
          }
        }}
      >
        <common.Box display="grid" gridGap="0" pb={0}>
          <InputField
            autoComplete="email"
            inputMode="email"
            prependIcon={<icons.Email />}
            scope="#/properties/email"
          />
          <InputPasswordField
            autoComplete="password"
            prependIcon={<icons.Lock />}
            scope="#/properties/password"
          />
        </common.Box>
        <common.Box
          my={0}
          fontSize={1}
          textAlign="right"
          textDecoration="underline"
        >
          <ReactRouter.Link
            data-testid="login-password-recovery"
            to="/password-recovery"
          >
            {i18n.translate('forgot-password')}
          </ReactRouter.Link>
        </common.Box>
        <common.Box pt={1} textAlign="center">
          <SubmitButton loading={loading}>
            {loading ? (
              <common.Box maxWidth="30px" maxHeight="30px" mt="-15px" mb="5px">
                <Spinner />
              </common.Box>
            ) : (
              i18n.translate('login.submit.label')
            )}
          </SubmitButton>
        </common.Box>
      </forms.Provider>
    </common.Box>
  )
}

// for @loadable/components
export default LoginFormBoom
